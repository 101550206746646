html, body {
  overflow: hidden;
  font-size: 1rem;
}

body {
  width: 600px;
  margin: 3vh auto 3vh;
  height: 90vh;
}

@media (max-width: 480px) {
  body {
    width: 96%;
  }
}

.App {
  text-align: center;

  max-width: 800px;
  margin: 10px auto 0;
  overflow: hidden;
}

.App-header {
  margin-bottom: 2rem;
  text-align: left;
}

.App-content {
  width: 86%;
}

.App-link {
  color: #61dafb;
}

.o-auth-btn {
  margin-top: 20px;
}

.calendar-picker {
  /*width: 68vw;*/
}

.calendar-picker .amplify-radio__label {
  margin-left: 10px;
}

.calendar-picker .amplify-radio {
  padding-top: 10px;
}

.subjects-preview {
  text-align: left;

  overflow-x: hidden;
}

